import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Conferences = ({ data }) => {
  const conferences = data.allFile.edges.map(
    edge => edge.node.childMarkdownRemark
  )

  return (
    <Layout>
      <SEO title="Conferences" />

      <header
        style={{ backgroundColor: "#3b5cad" }}
        className="px-4 sm:px-10 py-16 md:py-20 text-white"
      >
        <div className="max-w-lg mx-auto md:max-w-6xl">
          <h1 className="text-4xl sm:text-5xl lg:text-6xl font-medium border-b-4 border-palePink leading-none inline-block ">
            Conferences
          </h1>
        </div>
      </header>
      <div className="px-4 sm:px-10 flex justify-center">
        <div className="w-full max-w-lg md:max-w-6xl mt-20 border-t font-mono text-xl md:text-2xl">
          {conferences.map(conference => (
            <Link
              key={conference.fields.slug}
              className="hover:text-red flex justify-between py-8 border-b border-black"
              to={conference.fields.slug}
            >
              <span>{conference.frontmatter.title}</span>
              <span>{conference.frontmatter.year}</span>
            </Link>
          ))}
        </div>
      </div>
      <div className="px-4 sm:px-10 flex justify-center">
        <div className="w-full max-w-lg md:max-w-6xl mx-auto mt-16 md:mt-32 font-mono sm:text-lg md:text-xl lg:text-2xl">
          <p>
            We're always looking for passionate developers to join our team!
            <br />
            Learn more about careers at Podium{" "}
            <a href="https://podium.com/careers">here</a>.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Conferences

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(
      filter: {
        sourceInstanceName: { eq: "conference" }
        extension: { eq: "md" }
      }
      sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              url
              year: date(formatString: "YYYY")
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }
`
